import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import certificateGenerator from './certificateGenerator';
import country from './country';
import course from './course';
import dialog from './dialog';
import exam from './exam';
import header from './header';
import learningPaths from './learningPaths';
import lesson from './lesson';
import media from './media';
import module from './module';
import notification from './notification';
import progress from './progress';
import questions from './questions';
import rating from './rating';
import report from './report';
import setting from './setting';
import snackbar from './snackbar';
import system from './system';
import team from './team';
import user from './user';
import tsm from './tsm';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    certificateGenerator,
    country,
    course,
    dialog,
    exam,
    header,
    learningPaths,
    lesson,
    media,
    module,
    notification,
    progress,
    questions,
    rating,
    report,
    setting,
    snackbar,
    system,
    team,
    user,
    tsm,
  },
});
