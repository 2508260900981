import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';
import store from '../store/index';
import Store from '../store/index';

import certificateGenerator from './certificateGenerator';
import course from './course';
import dashboard from './dashboard';
import learningPaths from './learningPaths';
import login from './login';
import notification from './notification';
import profile from './profile';
import report from './report';
import setting from './setting';
import team from './team';
import user from './user';
import settingsTsm from './settingsTsm';

Vue.use(VueRouter);

const routes = [
  ...certificateGenerator,
  ...course,
  ...dashboard,
  ...learningPaths,
  ...login,
  ...notification,
  ...profile,
  ...report,
  ...setting,
  ...team,
  ...user,
  ...settingsTsm,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' };
  },
});

Vue.use(VueGtm, {
  id: 'GTM-WHB68M62',
  enabled: true,
  debug: true,
  loadScript: true,
  TrackEvent: true,
  vueRouter: router,
});

router.beforeEach(async (to, from, next) => {
  // starts loading preemptively to have better usability in the system
  store.commit('system/loading');
  // register current route in the system store
  store.commit('system/setRoute', to);

  if (
    !Store.state.auth.isLogged &&
    ![
      'login',
      'recovery',
      'verify-code',
      'login-reset',
      'login-register',
      'login-privacy',
    ].includes(to.name)
  ) {
    const isLogged = await Store.dispatch('auth/checkLogin');
    // var isAllowed = (
    // 		typeof Store.state.user.permissions[to.meta.tab] != 'undefined' &&
    // 		Store.state.user.permissions[to.meta.tab].read
    // 	)
    if (!isLogged) {
      next({ name: 'login' });
    } else {
      // await Store.dispatch("setting/list");
      next();
    }
  } else next();
});

export default router;
