import apiUtils from '../helpers/api_utils';
import * as usersService from '../services/users';
import { UserType } from '../helpers/user_utils';

const store = {
  namespaced: true,
  state: () => ({
    list: [],
    current: null,
    brazilId: null, // ID do país Brasil para referência
  }),
  getters: {
    list: (state) => state.list,
    current: (state) => state.current,
    brazilId: (state) => state.brazilId,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    setCurrent(state, tsm) {
      state.current = tsm;
    },
    addToList(state, tsm) {
      state.list.push(tsm);
    },
    updateInList(state, tsm) {
      const index = state.list.findIndex((item) => item._id === tsm._id);
      if (index !== -1) {
        state.list.splice(index, 1, tsm);
      }
    },
    removeFromList(state, id) {
      state.list = state.list.filter((item) => item._id !== id);
    },
    clearCurrent(state) {
      state.current = null;
    },
    setBrazilId(state, id) {
      state.brazilId = id;
    },
  },
  actions: {
    // Obter todos os usuários TSM configurados no sistema
    // Adicione ou atualize esta action no seu store
    async getAllTsm({ commit, rootGetters, dispatch }) {
      commit('system/loading', true, { root: true });
      const token = rootGetters['auth/userToken'];
      try {
        // Primeiro, buscamos o ID do Brasil para referência
        await dispatch('getBrazilId');

        // Buscar todos os usuários do tipo TSM
        const response = await apiUtils.get(
          'user/list',
          {
            type: 'tsm',
          },
          token
        );

        if (response.status) {
          // Acessar o array de usuários corretamente
          const users = response.body.list || [];

          // Mapear para o formato esperado pelo frontend
          const tsmList = users
            .filter((user) => user.TSM) // Apenas usuários com configuração TSM
            .map((user) => {
              const isBR =
                user.TSM && user.TSM.regions && user.TSM.regions.length > 0;
              return {
                _id: user._id || '',
                userId: user._id || '',
                name: user.name || '',
                email: user.email || '',
                regionType: isBR ? 'BR' : 'LAAM',
                regions: (user.TSM && user.TSM.regions) || [],
                countries: (user.TSM && user.TSM.countries) || [],
              };
            });

          commit('setList', tsmList);
          return tsmList;
        }
        return [];
      } catch (error) {
        console.error('Error getting TSM list:', error);
        throw error;
      } finally {
        commit('system/loading', false, { root: true });
      }
    },

    // Obter informações de um TSM específico
    async getTsm({ commit, rootGetters, dispatch }, id) {
      commit('system/loading', true, { root: true });
      const token = rootGetters['auth/userToken'];
      try {
        // Primeiro, buscamos o ID do Brasil para referência, se ainda não tivermos
        await dispatch('getBrazilId');

        // Buscar o usuário
        const response = await apiUtils.get(`user/get/${id}`, {}, token);

        if (response.status) {
          const user = response.body;
          const tsmData = {
            _id: user._id,
            userId: user._id,
            name: user.name,
            email: user.email,
            regionType:
              user.TSM && user.TSM.regions && user.TSM.regions.length > 0
                ? 'BR'
                : 'LAAM',
            regions: (user.TSM && user.TSM.regions) || [],
            countries: (user.TSM && user.TSM.countries) || [],
          };

          commit('setCurrent', tsmData);
          return tsmData;
        }
        return null;
      } catch (error) {
        console.error('Error getting TSM:', error);
        throw error;
      } finally {
        commit('system/loading', false, { root: true });
      }
    },

    async updateTsmConfig({ commit, rootGetters, state }, data) {
      commit('system/loading', true, { root: true });
      const token = rootGetters['auth/userToken'];
      try {
        // Buscar dados atuais do usuário
        const userResponse = await apiUtils.get(
          `user/get/${data.userId}`,
          {},
          token
        );

        if (!userResponse.status || !userResponse.body) {
          throw new Error('Falha ao obter dados do usuário');
        }

        const userData = userResponse.body;
        console.log('Dados originais do usuário:', userData);

        // Abordagem 1: Usar TSM diretamente
        const directPayload = {
          _id: data.userId,
          name: userData.name,
          email: userData.email,
          status: userData.status || 'enabled',
          type: 'tsm',
          TSM:
            data.regionType === 'BR'
              ? {
                  regions: data.regions,
                  countries: ['609c084f310e65f7ca19280d'],
                }
              : {
                  regions: [],
                  countries: data.countries,
                },
        };

        console.log('Payload direto para TSM:', directPayload);

        // Tentar atualização direta
        const directResponse = await apiUtils.post(
          'user/update',
          directPayload,
          token
        );
        console.log('Resposta da atualização direta:', directResponse);

        // Verificar se deu certo
        const verifyResponse = await apiUtils.get(
          `user/get/${data.userId}`,
          {},
          token
        );
        console.log(
          'Verificação após atualização direta:',
          verifyResponse.body && verifyResponse.body.TSM
        );

        // Verificar se a atualização foi bem-sucedida
        const success =
          verifyResponse.body &&
          verifyResponse.body.TSM &&
          ((data.regionType === 'BR' &&
            verifyResponse.body.TSM.regions &&
            verifyResponse.body.TSM.regions.length === data.regions.length) ||
            (data.regionType === 'LAAM' &&
              verifyResponse.body.TSM.countries &&
              verifyResponse.body.TSM.countries.length ===
                data.countries.length));

        // Se não deu certo, tentar a abordagem via isTSM
        if (!success) {
          console.log(
            'Atualização direta não funcionou completamente, tentando via isTSM...'
          );

          const isTsmPayload = {
            _id: data.userId,
            name: userData.name,
            email: userData.email,
            status: userData.status || 'enabled',
            type: 'tsm',
            isTSM: true,
            TSMCountry:
              data.regionType === 'BR'
                ? ['609c084f310e65f7ca19280d']
                : data.countries,
            TSMState: data.regionType === 'BR' ? data.regions : [],
          };

          console.log('Payload via isTSM:', isTsmPayload);

          const isTsmResponse = await apiUtils.post(
            'user/update',
            isTsmPayload,
            token
          );
          console.log('Resposta via isTSM:', isTsmResponse);

          // Verificar novamente
          const finalVerify = await apiUtils.get(
            `user/get/${data.userId}`,
            {},
            token
          );
          console.log(
            'Verificação final:',
            finalVerify.body && finalVerify.body.TSM
          );
        }

        // Retornar dados para a interface, baseado no que realmente foi salvo
        const finalData = await apiUtils.get(
          `user/get/${data.userId}`,
          {},
          token
        );
        if (finalData.status && finalData.body) {
          const tsm = finalData.body.TSM || {};

          return {
            _id: data.userId,
            userId: data.userId,
            name: finalData.body.name,
            email: finalData.body.email,
            regionType: tsm.regions && tsm.regions.length > 0 ? 'BR' : 'LAAM',
            regions: tsm.regions || [],
            countries: tsm.countries || [],
          };
        }

        // Fallback se não conseguir obter os dados finais
        return {
          _id: data.userId,
          userId: data.userId,
          name: userData.name,
          email: userData.email,
          regionType: data.regionType,
          regions: data.regionType === 'BR' ? data.regions : [],
          countries: data.regionType === 'LAAM' ? data.countries : [],
        };
      } catch (error) {
        console.error('Erro ao atualizar TSM:', error);
        throw error;
      } finally {
        commit('system/loading', false, { root: true });
      }
    },

    async getBrazilId({ commit, rootGetters, state }) {
      // Se já temos o ID do Brasil, não precisamos buscar novamente
      if (state.brazilId) return state.brazilId;

      const token = rootGetters['auth/userToken'];
      try {
        const response = await apiUtils.get(
          'country/find-by-name',
          { name: 'BRAZIL' },
          token
        );

        if (response.status && response.body) {
          commit('setBrazilId', response.body._id);
          return response.body._id;
        }
        return null;
      } catch (error) {
        console.error('Error getting Brazil ID:', error);
        return null;
      }
    },
    async getCountries({ commit, rootGetters }) {
      const token = rootGetters['auth/userToken'];
      try {
        const response = await apiUtils.get(
          'country/list',
          { active: true },
          token
        );

        console.log('Resposta da API de países:', response);

        if (response.status && response.body && response.body.items) {
          // A lista de países está em response.body.items
          return response.body.items;
        }

        console.warn(
          'API de países não retornou a estrutura esperada:',
          response
        );
        return [];
      } catch (error) {
        console.error('Error fetching countries:', error);
        return [];
      }
    },

    async testUpdateUserData({ rootGetters }, userId) {
      try {
        const token = rootGetters['auth/userToken'];

        const userResponse = await apiUtils.get(
          `user/get/${userId}`,
          {},
          token
        );
        console.log('Dados atuais do usuário:', userResponse);

        if (userResponse.status && userResponse.body) {
          console.log('Estrutura atual do campo TSM:', userResponse.body.TSM);
        }

        return userResponse;
      } catch (error) {
        console.error('Erro ao testar dados do usuário:', error);
        return null;
      }
    },
  },
};

export default store;
