export default {
  alerts: {
    general: {
      buttons: {
        cancel: 'cancel',
        accept: 'yes',
      },
      successCreate: 'Created with success!',
      errorCreate: 'Some field was filled out incorrectly',
      successEdit: 'Edited with success!',
      errorEdit: 'Error to edit',
      answerSelectError: 'Select the correct answer!',
      verifyAnswers: 'Create at least 2 answers',
      verifyLanguages: 'Check fields for another languages',
      leastOneQuestion: 'You need to create at least one question',
      errorToDownload: 'Error to download',
      loginExpired: 'Login expired',
      pendindUsers: 'user(s) awaiting approval',
    },
    login: {
      errorPassword: 'Invalid email/password combination!',
      errorRecovery: 'Invalid email',
      errorCode: 'Invalid code',
    },
    courses: {
      llSaveOrder: 'The order will be saved soon!',
      successSavedOrder: 'Order saved!',
      remove: {
        title: 'Remove',
        content: 'Do you want remove this course?',
      },
      removeModule: {
        title: 'Remove',
        content: 'Do you want remove this module?',
      },
      youNeedWait: 'You need wait {count} {label} to try again.',
      minToPass:
        'To earn the certificate, you need to pass {min}% of the test.',
      manyTries: 'Too many tries, please contact your master.',
      beforeExam:
        'You need to complete all of the previous modules to take this Exam.',
      removeQuestion: {
        title: 'Warning',
        content: 'Are you sure?',
      },
      removeAnswer: {
        title: 'Warning',
        content: 'Are you sure?',
      },
      removeMaterial: {
        title: 'Warning',
        content: 'Are you sure?',
      },
    },
    users: {
      remove: {
        title: 'Remove',
        content: 'Do you want remove this user?',
      },
      notFound: 'User not found',
      errorSaved: 'Please review your form before saving',
    },
    teams: {
      remove: {
        title: 'Remove',
        content: 'Do you want remove this team?',
      },
      notFound: 'Team not found',
    },
    learningPaths: {
      remove: {
        title: 'Remove',
        content: 'Do you want remove this learning path?',
      },
    },
    profile: {
      languageUpdade: {
        success: 'System Language change with success',
      },
      resetPassword: {
        title: 'Warning',
        content: 'Do you want to reset your password?',
      },
    },
    report: {
      noDataToExport: 'There is no data to be exported',
    },
    settings: {
      globalLabel: {
        title: 'Warning',
        content: 'Do you add a Global Label to all users and courses?',
        success: {
          title: 'Success',
          content: 'Global Labels will be added with success.',
        },
      },
      initSettings: {
        title: 'Warning',
        content: 'Do you want to start the database with the default settings?',
        success: {
          title: 'Success',
          content: 'The definitions will be updated with success.',
        },
      },
      clearDBCache: {
        title: 'Warning',
        content: 'Do you want to clear DB cache?',
        success: {
          title: 'Success',
          content: 'The DB cache will be cleared with success.',
        },
      },
      fixInvalidDate: {
        title: 'Warning',
        content:
          'Do you want to fix invalid dates for users who have finished courses?',
        success: {
          title: 'Success',
          content: 'The invalid dates will be fixes on DB.',
        },
      },
      master: {
        removeDistributor: {
          title: 'Warning',
          content:
            'Do you want remove this distributor? When removing the distributor, all linked users will be disabled.',
        },
      },
    },
  },
  labels: {
    accept: 'Accept',
    actions: 'Actions',
    addAnswer: 'Add answer',
    addLesson: 'Add lesson',
    addMaterial: 'Add material',
    addModule: 'Add module',
    addQuestion: 'Add question',
    addDistribuitor: 'Add distributor',
    admin: 'Admin',
    all: 'All',
    allCourses: 'All Courses',
    answer: 'Answer',
    answerList: 'Answer list:',
    answerListEmpty: 'Answer list is empty',
    attempts: 'Attempts',
    approved: 'Approved',
    back: 'back',
    backToCourse: 'back to course',
    backToCourses: 'back to courses',
    backToLearningPaths: 'back to learning paths',
    backToTeam: 'back to team',
    backToTeams: 'back to teams',
    backToUsers: 'back to users',
    cancel: 'cancel',
    certificateLayout: 'Certificate layout',
    certificateGenerator: 'Certificate generator',
    change: 'Change',
    championSince: 'Champion Since',
    city: 'City',
    close: 'Close',
    country: 'Country',
    countries: 'countries',
    courses: 'Courses',
    create: 'create',
    createAccount: 'Create account',
    createCourse: 'create course',
    createdAt: 'created at',
    createLearningPaths: 'create learning path',
    createModule: 'Create module',
    createTeam: 'Create Team',
    createUser: 'create user',
    customDate: 'Custom date',
    dashboard: 'dashboard',
    description: 'description',
    dialCode: 'Dial Code',
    disabled: 'Disabled',
    disableds: 'Disableds',
    distributor: 'Distributor',
    done: 'Done',
    downloadCertificate: 'Download Certificate',
    fact: 'FACT',
    edit: 'edit',
    edit: 'Edit',
    editCourse: 'edit course',
    editLesson: 'edit lesson',
    editedWithSuccess: 'Edited with success!',
    editLearningPaths: 'edit learning path',
    editModule: 'Edit module',
    editTeam: 'Edit Team',
    editTsm: 'Edit TSM',
    editUser: 'edit user',
    email: 'email',
    email: 'Email',
    en: 'english',
    enabled: 'Enabled',
    enableds: 'Enableds',
    endCourse: 'End course',
    enrollmentDate: 'Enrollment Date',
    errorLoadAnswers: 'Error to load answers',
    es: 'spanish',
    exam: 'exam',
    examAnswers: 'Exam Answers',
    examAttempts: 'Exam Attempts',
    exportXLS: 'Export XLS',
    file: 'File',
    fileWillBeRemove: 'The file will be removed on apply',
    filter: 'Filter',
    filterAdmins: 'Admins',
    filterLocale: 'Language',
    filterMasters: 'Masters',
    filterStatus: 'Status',
    filterStudents: 'Students',
    filterTSM: 'TSMs',
    flukeAcademyID: 'Fluke Academy ID',
    forgot: 'Forgot password',
    generateCertificate: 'Generate certificate upon exam completion',
    generateCertificateAtTheEnd:
      'Generate certificate at the end of the course',
    image: 'Image',
    insufficientScore: 'Insufficient score',
    isOptionalModule: 'Optional module',
    hours: 'Hours',
    language: 'Language',
    languages: 'languages',
    lastAttempt: 'Last attempt',
    lessons: 'lessons',
    learningPaths: 'learning paths',
    learningPath: 'learning path',
    LMSInfo: 'LMS Information',
    loading: 'Loading...',
    locationData: 'Location Data',
    loginAsUser: 'Login as this user',
    login: 'login',
    logout: 'Logout',
    master: 'Master',
    master: 'Master',
    masters: 'Masters',
    materials: 'Materials',
    masterDistributor: 'Master / Distributor',
    materialName: 'Material name',
    maxAttempsNumbers:
      'You have exhausted the {attemptsNumber} attempts available for this test.',
    minimumGrade: 'Minimum grade',
    moduleDescription: 'Module Description',
    moduleMarkAsRead: 'Mark module as viewed',
    moduleWasRead: 'Complete',
    myProgress: 'My Progress',
    myCertificates: 'My Certificates',
    name: 'name',
    new: 'New',
    newCourse: 'New course',
    newLearningPath: 'New Learning Path',
    newTeam: 'New team',
    next: 'next',
    nextLesson: 'Next lesson',
    noAttempt: 'You have no more attempts',
    noLearningPaths: 'You don´t have Learning paths yet',
    noTeams: 'You are not part of a team yet',
    notifications: 'notifications',
    others: 'others',
    password: 'password',
    pending: 'Pending',
    pdf: 'PDF',
    pdf: 'PDF',
    personalData: 'Personal Data',
    personalInfo: 'Personal Information',
    phone: 'Phone',
    post: 'Text',
    privacy: 'Privacy Policy',
    profile: 'Profile',
    profilePicture: 'Profile Picture',
    progress: 'In progress',
    pt: 'portuguese',
    qty: 'Amount',
    question: 'Question',
    questionList: 'Question list:',
    questionListEmpty: 'Question list is empty',
    remainig: 'remaining',
    remove: 'Remove',
    region: 'Region',
    rejected: 'Rejected',
    rejecteds: 'Rejecteds',
    reports: 'reports',
    requireCompletedModulesBeforeExam: 'Require completed lessons before exam',
    reset: 'Reset',
    recovery: 'Recovery password',
    resetPassword: 'reset password',
    resetPassword: 'Reset Password',
    restartExam: 'Retry exam',
    startExam: 'Começar exame',
    result: 'Result',
    save: 'save',
    saveAnswer: 'Save Answer',
    saveQuestion: 'SAVE QUESTION',
    search: 'Search',
    seeAnswers: 'See answers',
    selectAll: 'select all',
    send: 'Send',
    settings: 'settings',
    state: 'State',
    states: 'States',
    status: {
      disabled: 'Disabled',
      done: 'Done',
      draft: 'Draft',
      enabled: 'Enabled',
      in_progress: 'In progress',
      pending: 'Pending',
      published: 'Published',
      rejected: 'Rejected',
      undefined: 'Undefinned',
    },
    student: 'Student',
    students: 'Students',
    structure: 'Course structure',
    selectCountries: 'Select countries',
    selectStates: 'Select states',
    supportMaterial: 'Support material',
    teamLeader: 'Leader | Leaders',
    teamMembers: 'Team members',
    teamMembersCount: 'student | studants',
    teamName: 'Team name',
    team: 'team',
    teams: 'Teams',
    title: 'title',
    trainingCategory: 'Training Categories',
    try: 'Try',
    tryAgain: 'Try again',
    tsm: 'TSM',
    trainingType: 'Training Type',
    trainingTypes: 'Training Types',
    TSMCategory: 'TSM Category',
    TSMCountry: 'TSM Country',
    TSMInfo: 'TSM Information',
    TSMRegion: 'TSM Region',
    type: 'Type',
    userCategory: 'User Category',
    userClassification: 'User Classification',
    users: 'users',
    userStatus: 'User Status',
    userType: 'User Type',
    value: 'value',
    video: 'video',
    vimeoID: 'Vimeo video ID',
    welcomeCreatePass:
      'Welcome to the new platform! Since this is your first access, we have sent a link to your e-mail for you to create a new password.',
    youNeddChooseTheRightAnswer: 'You need to choose the right answer',
    youNeddTwoQuestion: 'You need two questions at least!',
    youNeedOneQuestion: 'You need one question at least!',
  },
  rules: {
    allLangsField: 'Required in all languages',
    required: 'Required',
  },
  texts: {
    dash: {
      dev: 'in development',
    },
  },
  settings: {
    names: {
      functions: 'functions',
      customCertificate: 'create certificate',
      addGlobalLabel: 'add global label',
      initGlobalDef: 'Init global definitions',
      clearDBCache: 'clear DB cache',
      data: 'data',
      config: 'Config',
      time_wait_exam_tries: 'Time to wait between exam tries (in hours)',
      max_exam_tries: 'Maximum exam tries',
      sendgrid_api_key: 'Sendgrid API Key',
      mail_from: 'From email',
      site_url: 'Site URL',
      api_url: 'API URL',
      trainingType: 'training type',
      userCategory: 'user categories',
      countryDist: 'coutries/distributors',
      fixInvalidDate: 'Fix invalid date from Done Courses',
      checkUsers: 'Check Users',
      editTsm: 'Edit TSM',
      tsmManage: 'TSM Manage',
    },
  },
  loginPage: {
    accept: {
      text: 'I accept the ',
      link: 'privacy policy',
    },
    email: 'Email',
    back: 'back',
    name: 'Full Name',
    password: 'Password',
    passwordConfirmation: 'Password confirmation',
    country: 'Country',
    state: 'State',
    master: 'Master',
    langs: {
      portuguese: 'Portuguese',
      english: 'English',
      spanish: 'Spanish',
    },
    pages: {
      recovery: {
        title: 'Recovery password',
        disclaimer: 'Type your email to receive a link to reset your password',
      },
      verifyCode: {
        title: 'Verify code',
        disclamer: 'Type the code you received by email',
      },
      forgot: {
        title: 'Forgot password',
        text: 'Type your e-mail to receive a link to create a new password',
        invalidEmail: 'Invalid email',
        success: 'Link sent for your email! Check it in a few minutes',
        error: 'Account not found',
      },
      reset: {
        title: 'Change password',
        text: 'Create a new password',
        newPass: 'New password',
        confirmPass: 'Password confirmation',
        invalidPassword: 'Minimum 6 characters',
        invalidConfirmation: 'Password does not match!',
        goToLogin: 'Go to login',
        success: 'Password changed!',
        error: 'Invalid link',
      },
      register: {
        title: 'Create account',
        text: 'Create your account',
        success:
          'Registration successfully sent! We will moderate your registration, you will soon be granted access.',
        error: 'Unable to register, please try again',
        errorDuplicated:
          'Unable to register, this email is already registered!',
        goToLogin: 'Go to login',
        invalids: {
          name: 'Invalid name',
          email: 'Invalid email',
          password: 'Invalid password',
          country: 'Invalid country',
          state: 'Invalid state',
          master: 'Invalid master',
          passwordConfirmation: 'Invalid password confirmation',
          passwordConfirmationDifferent: 'Passwords do not match',
        },
      },
    },
  },
  reports: {
    back: 'back to reports',
    course: {
      course: 'Course',
      studentsCount: 'Total students',
      studentsProgress: 'Students doing',
      studentsDone: 'Students done',
      name: 'Name',
      progress: 'Progress',
      done: 'Done',
      totalStudents: 'Total students',
      doneStudents: 'Done',
      doneDate: 'Done date',
    },
    student: {
      name: 'Name',
      coursesCount: 'Courses doing',
      coursesDone: 'Courses done',
      totalCourses: 'Total courses',
      doneCourses: 'Done',
      course: 'Course',
      progress: 'Progress',
      done: 'Done',
      doneDate: 'Done date',
      certificateError: 'Error to generate certificate',
    },
  },
  notifications: {
    clear: 'Clear notifications',
    default: 'Everything is Clear',
    course: {
      add: 'A new Course was added',
      update: 'A Course was updated',
    },
    linearLesson: {
      add: 'A new lesson was added',
      update: 'A lesson was updated',
    },
    module: {
      add: 'A new module was added',
      update: 'A module was updated',
    },
    moduleLesson: {
      add: 'A new lesson in module was added',
      update: 'A lesson in module was updated',
    },
    team: {
      add: 'You have been added to a team',
      update: 'A team you are part of has been modified',
    },
    learningPath: {
      add: 'You have been added to a learning path',
      update: 'A learning path you are part of has been modified',
    },
  },
  rating: {
    title: 'Rating Overview',
    subtitle: 'Ratings',
  },
  users: {
    edit: {
      alertMissing:
        'There is some important data missing from your profile, please fill them!',
    },
    view: {
      profile: 'Profile',
      filterCourses: 'Courses',
      filterTeams: 'Teams',
      filterActivities: 'Activities',
      filterConquests: 'Conquests',
      statusLabel: 'Status',
      team: 'Team',
      leader: 'Leader',
      yes: 'Yes',
      activity: 'Activity',
      createdAt: 'Date',
      activityType: 'Type',
      activiteTypes: {
        course: 'Conquest',
        module: 'Module',
        user: 'Person',
      },
      activities: {
        course:
          '<strong>{name}</strong> won a new conquest <strong>{course}</strong>',
        module: '<strong>{name}</strong> done module <strong>{module}</strong>',
        user: 'Account <strong>{name}</strong> was updated by <strong>{updatedBy}</strong>',
      },
    },
  },
  apiStatus: {
    7: 'This email is already registered in another country',
  },
  profile: {
    options: {
      personalData: 'Personal Data',
      language: 'Language',
      resetPassword: 'Reset Password',
      logout: 'Logout',
    },
  },
};
