export default [
  {
    path: '/settings-tsm',
    name: 'settings-tsm',
    component: function () {
      return import(
        /* webpackChunkName: "settings-tsm" */ '../views/settingsTsm/Index.vue'
      );
    },
  },
];
